<template>
  <div class="popup">
    <div class="popup__close">
      <button @click="closeEvent">Close</button>
    </div>
    <div class="popup__list">
      {{ data.type }}
    </div>
    <div class="popup__img">
      <img :src="getImgUrl(data.type)" :alt="data.type" />
    </div>
    <div class="modal__label popup__label">
      <label for="place">Lieu</label>
      <input
        class="modal__input popup__input"
        type="text"
        name="place"
        id="place"
        v-model="place"
      />
    </div>
    <div class="modal__label popup__label">
      <label for="start">Date</label>
      <div class="modal__date">
        <select
          class="modal__input popup__input"
          name="type"
          id="type"
          v-model="date.jour"
        >
          <option value="-">Jours</option>
          <option v-for="n in 31" :key="n + 'x'" :value="n">{{ n }}</option>
        </select>
        <select
          class="modal__input popup__input"
          name="type"
          id="type"
          v-model="date.mois"
        >
          <option value="-">Mois</option>
          <option v-for="n in 12" :key="n + 'x'" :value="n">{{ n }}</option>
        </select>
        <select
          class="modal__input popup__input"
          name="type"
          id="type"
          v-model="date.annee"
        >
          <option value="-">Année</option>
          <option
            v-for="n in range(currentYear - 2, currentYear + 5)"
            :key="n + 'x'"
            :value="n"
            >{{ n }}</option
          >
        </select>
      </div>
    </div>
    <div class="modal__label popup__label">
      <label for="start">Heure</label>
      <input
        class="modal__input popup__input"
        type="time"
        name="hours"
        id="hours"
        v-model="hours"
      />
    </div>
    <div class="modal__label popup__label">
      <label for="start">Notes</label>
      <textarea
        class="modal__input popup__input"
        v-model="notes"
        placeholder="Your notes"
      ></textarea>
    </div>
    <div class="popup__images">
      <div class="popup__images-title">
        <label for="start">Photos</label>
        <button @click="chooseHandler">upload image</button>
        <input
          type="file"
          ref="imageInput"
          style="display: none"
          @change="previewImage"
          accept="image/*"
        />
      </div>
      <div class="popup_images-list" v-if="images.length > 0">
        <img
          v-for="img in images"
          :key="img"
          :src="img"
          :alt="img"
          @click="zoomImageHandler(img)"
        />
      </div>
    </div>
    <div class="popup__delete">
      <button @click="deleteEventHandler">Delete</button>
    </div>
    <div class="popup__image" v-if="zoomImage">
      <img :src="zoomImage" :alt="zoomImage" />
      <button class="image__close" @click="zoomImageHandler(null)">
        Close
      </button>
      <button class="image__delete" @click="deleteImageHandler">Delete</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  name: "PopupAgenda",
  data() {
    return {
      type: null,
      date: {
        jour: null,
        mois: null,
        annee: null
      },
      hours: null,
      place: null,
      notes: null,
      images: [],
      imageUpload: null,
      zoomImage: null
    };
  },
  computed: {
    data: {
      get() {
        this.setPropreties(this.$store.state.popupAgendaData);
        return this.$store.state.popupAgendaData;
      }
    },
    currentYear() {
      return parseInt(new Date().getFullYear());
    }
  },
  methods: {
    range: function(min, max) {
      let array = [],
        j = 0;
      for (let i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
    setPropreties(val) {
      const date = val.date.split("-");

      this.type = val.type;
      this.place = val.lieu;
      this.date.jour = parseInt(date[2]);
      this.date.mois = parseInt(date[1]);
      this.date.annee = parseInt(date[0]);
      this.hours = parseInt(val.heure);
      this.notes = val.notes;
      this.images = val.images !== undefined ? val.images : [];
    },
    chooseHandler() {
      this.$refs.imageInput.click();
    },
    previewImage(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("bad files");
      }
      this.imageUpload = files[0];
      this.onUpload();
    },
    onUpload() {
      const metadata = {
        contentType: `${this.imageUpload.type}`
      };

      const filename = this.imageUpload.name;

      const storageRef = firebase
        .storage()
        .ref(`${this.$store.state.userId}/${filename}`)
        .put(this.imageUpload, metadata);

      storageRef.on(
        `state_changed`,
        snapshot => {
          console.log(
            "Upload ",
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        error => {
          console.log(error.message);
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            console.log(url);
            this.images.push(url);
            this.setNewAgenda();
          });
        }
      );
    },
    setNewAgenda() {
      const obj = {
        type: "agenda",
        data: {
          lieu: this.place,
          date: this.date.annee + "-" + this.date.mois + "-" + this.date.jour,
          heure: this.hours,
          type: this.type,
          notes: this.notes,
          images: this.images
        }
      };

      this.$store.commit("updateUser", obj);
      this.$store.dispatch("updateUserOther");
    },
    closeEvent() {
      this.setNewAgenda();
      this.$store.dispatch("popupAgendaOpen", null);
    },
    zoomImageHandler(url) {
      this.zoomImage = url;
    },
    deleteImageHandler() {
      const arrayImagesDeleted = this.images.filter(
        el => el !== this.zoomImage
      );

      this.images = arrayImagesDeleted;

      this.setNewAgenda();
      this.zoomImageHandler(null);
    },
    deleteEventHandler() {
      this.$store.commit("deleteEventUser", "agenda");
      this.$store.dispatch("updateUserOther");
      this.$store.dispatch("popupAgendaOpen", null);
    },
    getImgUrl(pic) {
      // Get the images
      switch (pic) {
        case "Prélèvement PAC":
          return require("../assets/agenda/pac.svg");
        case "Consultation":
          return require("../assets/agenda/consultation.svg");
        case "Examen d'imagerie":
          return require("../assets/agenda/imagerie.svg");
        case "Chimiothérapie":
          return require("../assets/agenda/chimiotherapie.svg");
        case "Consultation Cardio":
          return require("../assets/agenda/cardio.svg");
        case "Admission hospitalisation":
          return require("../assets/agenda/admission.svg");
        case "Simulation radiothérapie":
          return require("../assets/agenda/radiotherapie.svg");
        case "Traitement de radiothérapie":
          return require("../assets/agenda/radiotherapie.svg");
        case "Pansements chirurgie plastique":
          return require("../assets/agenda/pansement.svg");
        case "Chirurgie":
          return require("../assets/agenda/chirurgie.svg");
        case "Mammographie":
          return require("../assets/agenda/mammographie.svg");
        case "Kinésithérapie":
          return require("../assets/agenda/kinesitherapie.svg");
        case "Psychologue":
          return require("../assets/agenda/psychologue.svg");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.popup {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  overflow: scroll;
  &__container {
    width: 100%;
    margin: 0;
  }
  label {
    text-align: left;
    margin-top: 20px;
    font-size: 16px;
    color: #3b5150;
  }
  &__delete {
    button {
      background-color: #fa5b5b;
      color: #fff;
      padding: 15px 30px 15px 30px;
      border: none;
      font-weight: 600;
      font-size: 15px;
      display: block;
      margin: 20px auto;
      border-radius: 18px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  &__image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;

    img {
      width: 80%;
      height: auto;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      z-index: -1;
    }

    p {
      position: absolute;
      top: 20px;

      &:first-of-type {
        left: 20px;
      }

      &:last-of-type {
        right: 20px;
        color: red;
      }
    }
  }
  .image {
    &__delete {
      background-image: url(../assets/icon/delete.svg);
      background-repeat: no-repeat;
      overflow: hidden;
      display: block;
      text-indent: 100%;
      border: none;
      background-color: inherit;
      width: 40px;
      height: 40px;
      background-size: contain;
      position: fixed;
      bottom: 20px;
      cursor: pointer;
    }

    &__close {
      background-image: url(../assets/icon/cross.svg);
      background-repeat: no-repeat;
      overflow: hidden;
      display: block;
      text-indent: 100%;
      border: none;
      background-color: inherit;
      width: 20px;
      height: 20px;
      background-size: contain;
      position: fixed;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }
}
</style>

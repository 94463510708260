<template>
  <div class="container__modal">
    <div class="click" @click="onCloseHandler"></div>
    <div class="modal">
      <div class="modal__menu">
        <div
          class="modal__el"
          :class="{ active: isAgenda }"
          @click="isAgenda = true"
        >
          <p>Agenda</p>
        </div>
        <div
          class="modal__el"
          :class="{ active: !isAgenda }"
          @click="isAgenda = false"
        >
          <p>Progression</p>
        </div>
      </div>
      <div class="modal__agenda" v-if="isAgenda">
        <form @submit.prevent="() => onSubmit('agenda')">
          <select
            class="modal__input"
            name="type"
            id="type"
            v-model="agenda.type"
          >
            <option value="-">Type de rendez-vous</option>
            <option value="Prélèvements PAC">Prélèvements PAC</option>
            <option value="Consultation">Consultation</option>
            <option value="Examen d'imagerie">Examen d'imagerie</option>
            <option value="Chimiothérapie">Chimiothérapie </option>
            <option value="Consultation Cardio">Consultation Cardio</option>
            <option value="Admission hospitalisation"
              >Admission hospitalisation</option
            >
            <option value="Simulation radiothérapie"
              >Simulation radiothérapie</option
            >
            <option value="Traitement de radiothérapie"
              >Traitement de radiothérapie</option
            >
            <option value="Pansements chirurgie plastique"
              >Pansements chirurgie plastique</option
            >
            <option value="Chirurgie">Chirurgie</option>
            <option value="Mammographie">Mammographie</option>
            <option value="Kinésithérapie">Kinésithérapie</option>
            <option value="Psychologue">Psychologue</option>
          </select>
          <div class="modal__label">
            <label for="place">Lieu</label>
            <input
              class="modal__input"
              type="text"
              name="place"
              id="place"
              v-model="agenda.place"
            />
          </div>
          <div class="modal__label">
            <label for="start">Date</label>
            <div class="modal__date">
              <select
                class="modal__input"
                name="type"
                id="type"
                v-model="agenda.date.day"
              >
                <option value="-">Jours</option>
                <option v-for="n in 31" :key="n + 'x'" :value="n">{{
                  n
                }}</option>
              </select>
              <select
                class="modal__input"
                name="type"
                id="type"
                v-model="agenda.date.month"
              >
                <option value="-">Mois</option>
                <option v-for="n in 12" :key="n + 'x'" :value="n">{{
                  n
                }}</option>
              </select>
              <select
                class="modal__input"
                name="type"
                id="type"
                v-model="agenda.date.year"
              >
                <option value="-">Année</option>
                <option
                  v-for="n in range(currentYear - 2, currentYear + 5)"
                  :key="n + 'x'"
                  :value="n"
                  >{{ n }}</option
                >
              </select>
            </div>
          </div>
          <div class="modal__label">
            <label for="start">Heure</label>
            <input
              class="modal__input"
              type="time"
              name="hours"
              id="hours"
              v-model="agenda.hours"
            />
          </div>
          <div class="modal__label">
            <label for="start">Notes</label>
            <textarea
              class="modal__input"
              v-model="agenda.notes"
              placeholder="Your notes"
            ></textarea>
          </div>
          <button type="submit">Ajouter</button>
        </form>
      </div>
      <div class="modal__progression" v-else>
        <form @submit.prevent="() => onSubmit('progression')">
          <select
            class="modal__input"
            name="type"
            id="type"
            v-model="progression.type"
          >
            <option value="-">Type de traitement</option>
            <option value="Découverte de la maladie"
              >Découverte de la maladie</option
            >
            <option value="Chimiothérapie taxotere"
              >Chimiothérapie taxotere</option
            >
            <option value="Chimiothérapie epirubicine"
              >Chimiothérapie epirubicine</option
            >
            <option value="Radiothérapie">Radiothérapie</option>
            <option value="Chimiothérapie xeloda">Chimiothérapie xeloda</option>
            <option value="Chirurgie">Chirurgie</option>
            <option value="1 an de traitement">1 an de traitement</option>
          </select>
          <div class="modal__label">
            <label for="start">Début du traitement</label>
            <div class="modal__date">
              <select
                class="modal__input"
                name="type"
                id="type"
                v-model="progression.dateStart.day"
              >
                <option value="-">Jours</option>
                <option v-for="n in 31" :key="n + 'x'" :value="n">{{
                  n
                }}</option>
              </select>
              <select
                class="modal__input"
                name="type"
                id="type"
                v-model="progression.dateStart.month"
              >
                <option value="-">Mois</option>
                <option v-for="n in 12" :key="n + 'x'" :value="n">{{
                  n
                }}</option>
              </select>
              <select
                class="modal__input"
                name="type"
                id="type"
                v-model="progression.dateStart.year"
              >
                <option value="-">Année</option>
                <option
                  v-for="n in range(currentYear - 2, currentYear + 5)"
                  :key="n + 'x'"
                  :value="n"
                  >{{ n }}</option
                >
              </select>
            </div>
          </div>
          <div class="modal__label">
            <label for="start">Fin du traitement</label>
            <div class="modal__date">
              <select
                class="modal__input"
                name="type"
                id="type"
                v-model="progression.dateEnd.day"
              >
                <option value="-">Jours</option>
                <option v-for="n in 31" :key="n + 'x'" :value="n">{{
                  n
                }}</option>
              </select>
              <select
                class="modal__input"
                name="type"
                id="type"
                v-model="progression.dateEnd.month"
              >
                <option value="-">Mois</option>
                <option v-for="n in 12" :key="n + 'x'" :value="n">{{
                  n
                }}</option>
              </select>
              <select
                class="modal__input"
                name="type"
                id="type"
                v-model="progression.dateEnd.year"
              >
                <option value="-">Année</option>
                <option
                  v-for="n in range(currentYear - 2, currentYear + 5)"
                  :key="n + 'x'"
                  :value="n"
                  >{{ n }}</option
                >
              </select>
            </div>
            <div class="modal__label">
              <label for="start">Nombre de séances</label>
              <input
                class="modal__input"
                type="number"
                name="number"
                id="number"
                v-model="progression.numberMeeting"
              />
            </div>
          </div>
          <div class="modal__label">
            <label for="start">Notes</label>
            <textarea
              class="modal__input"
              v-model="progression.notes"
              placeholder="Your notes"
            ></textarea>
          </div>
          <button type="submit">Ajouter</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      progression: {
        dateStart: {
          year: "-",
          month: "-",
          day: "-"
        },
        dateEnd: {
          year: "-",
          month: "-",
          day: "-"
        },
        notes: "",
        numberMeeting: 0,
        type: "-"
      },
      agenda: {
        type: "-",
        place: "",
        date: {
          year: "-",
          month: "-",
          day: "-"
        },
        hours: "",
        notes: ""
      },
      isAgenda: true
    };
  },
  computed: {
    currentYear() {
      return parseInt(new Date().getFullYear());
    }
  },
  methods: {
    onSubmit(type) {
      if (type === "progression") {
        const errors = [];
        // Check error type
        this.progression.type == "-" ? errors.push("type") : "";
        // Check date's
        new Date(
          this.progression.dateStart.year,
          this.progression.dateStart.month,
          this.progression.dateStart.day
        ) >
        new Date(
          this.progression.dateEnd.year,
          this.progression.dateEnd.month,
          this.progression.dateEnd.day
        )
          ? errors.push("badDate")
          : "";

        if (errors.length > 0) {
          console.log("errors : ", errors);
        } else {
          const obj = {
            type: "events",
            data: {
              dateDebut:
                this.progression.dateStart.year +
                "-" +
                this.progression.dateStart.month +
                "-" +
                this.progression.dateStart.day,
              dateFin:
                this.progression.dateEnd.year +
                "-" +
                this.progression.dateEnd.month +
                "-" +
                this.progression.dateEnd.day,
              type: this.progression.type,
              nbrSeance: this.progression.numberMeeting,
              notes: this.progression.notes,
              images: []
            }
          };
          this.$store.commit("saveNewDataUser", obj);
          this.$store.dispatch("updateUserOther");
          this.onCloseHandler();
        }
      } else {
        const errors = [];

        this.agenda.place !== "" ? "" : errors.push("place");
        this.agenda.type == "-" ? errors.push("type") : "";
        this.agenda.hours !== "" ? "" : errors.push("hours");

        if (errors.length > 0) {
          console.log("errors : ", errors);
        } else {
          const obj = {
            type: "agenda",
            data: {
              lieu: this.agenda.place,
              date:
                this.agenda.date.year +
                "-" +
                this.agenda.date.month +
                "-" +
                this.agenda.date.day,
              heure: this.agenda.hours,
              type: this.agenda.type,
              notes: this.agenda.notes,
              images: []
            }
          };
          this.$store.commit("saveNewDataUser", obj);
          this.$store.dispatch("updateUserOther");
          this.onCloseHandler();
        }
      }
    },
    range: function(min, max) {
      let array = [],
        j = 0;
      for (let i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
    onCloseHandler() {
      this.$store.commit("setModal");
      this.resetFields();
    },
    resetFields() {
      // Reset the fields at close
      this.progression = {
        dateStart: {
          year: null,
          month: null,
          day: null
        },
        dateEnd: {
          year: null,
          month: null,
          day: null
        },
        notes: "",
        numberMeeting: 0,
        type: "-"
      };

      this.agenda = {
        type: "-",
        place: "",
        date: {
          year: null,
          month: null,
          day: null
        },
        hours: "",
        notes: ""
      };
    }
  }
};
</script>

<style lang="scss">
.container__modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.modal {
  position: relative;
  flex-direction: column;
  margin: auto 20px;
  min-height: 70%;
  background-color: #fbfbfb;
  align-items: flex-start;
  justify-content: space-between;
  top: 10%;
  border-radius: 5px;

  &__menu {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 10px;
    background-color: #95cdcb;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #fff;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__el {
    &.active {
      background-color: #75acaa;
      padding: 10px 30px;
      border-radius: 18px;
    }
  }

  &__agenda {
    padding: 20px 35px;
    text-align: left;
  }
  &__progression {
    padding: 20px 35px;
    text-align: left;
  }

  &__input {
    padding: 10px;
    border: none;
    background-color: #fff;
    box-shadow: 0px 10px 10px rgba(13, 78, 129, 0.051);
    border-radius: 5px;
  }

  &__label {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-size: 14px;
    color: #3b5150;
  }
  &__date {
    display: flex;
    flex-direction: row;
    width: 100%;
    place-content: space-between;
    column-gap: 20px;

    input {
      padding: 25px;
      border: none;
      border-radius: 5px;
      box-shadow: 0px 10px 10px rgba(13, 78, 129, 0.051);
    }
  }

  button {
    color: #fff;
    padding: 15px 30px;
    background-color: #95cdcb;
    border: none;
    font-weight: 600;
    font-size: 15px;
    display: block;
    margin: 20px auto;
    border-radius: 18px;
    text-transform: uppercase;
  }

  select {
    width: 100%;
  }
  label {
    margin-bottom: 5px;
  }
}

.click {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-height: 640px) {
  .modal {
    button {
      margin: 10px auto;
      padding: 7px 15px;
    }
    &__label {
      margin-top: 10px;
    }
  }
}

@media (max-width: 340px) {
  .modal {
    top: 2%;
  }
}
@media (max-width: 375px) {
  .modal {
    top: 6%;
  }
}

@media (min-width: 800px) {
  .modal {
    width: 50%;
    margin: auto;
    top: 2%;
    &__input {
      padding: 20px;
    }
    button {
      cursor: pointer;
      &:hover {
        background-color: #75acaa;
        transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
      }
    }
    &__el {
      cursor: pointer;
      &:hover {
        background-color: #75acaa;
        padding: 10px 30px;
        border-radius: 18px;
        transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
      }
    }
  }
}
</style>

<template>
  <div class="popup">
    <div class="popup__container popup__desktop">
      <div class="popup__close">
        <button @click="closeEvent">Close</button>
      </div>
      <div class="popup__list">
        {{ data.type }}
      </div>
      <div class="popup__box">
        <div class="popup__img">
          <img :src="getImgUrl(data.type)" :alt="data.type" />
        </div>
        <div class="popup__container">
          <div class="popup__tips" v-if="conseils.length > 0">
            <h4>Conseil durant votre traitement</h4>
            <ul>
              <li v-for="conseil in conseils" :key="conseil">
                {{ conseil }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="popup__box--desktop">
        <div class="popup__box">
          <div class="popup__label">
            <label for="start">Début du traitement</label>
            <div class="popup__date">
              <select class="popup__input" name="type" v-model="dateDebut.jour">
                <option value="-">Jours</option>
                <option v-for="n in 31" :key="n + 'x'" :value="n">{{
                  n
                }}</option>
              </select>
              <select class="popup__input" name="type" v-model="dateDebut.mois">
                <option value="-">Mois</option>
                <option v-for="n in 12" :key="n + 'x'" :value="n">{{
                  n
                }}</option>
              </select>
              <select
                class="popup__input"
                name="type"
                v-model="dateDebut.annee"
              >
                <option value="-">Année</option>
                <option
                  v-for="n in range(currentYear - 2, currentYear + 5)"
                  :key="n + 'x'"
                  :value="n"
                  >{{ n }}</option
                >
              </select>
            </div>
          </div>

          <div class="popup__label">
            <label for="start">Fin du traitement</label>
            <div class="popup__date">
              <select class="popup__input" name="type" v-model="dateFin.jour">
                <option value="-">Jours</option>
                <option v-for="n in 31" :key="n + 'x'" :value="n">{{
                  n
                }}</option>
              </select>
              <select class="popup__input" name="type" v-model="dateFin.mois">
                <option value="-">Mois</option>
                <option v-for="n in 12" :key="n + 'x'" :value="n">{{
                  n
                }}</option>
              </select>
              <select class="popup__input" name="type" v-model="dateFin.annee">
                <option value="-">Année</option>
                <option
                  v-for="n in range(currentYear - 2, currentYear + 5)"
                  :key="n + 'x'"
                  :value="n"
                  >{{ n }}</option
                >
              </select>
            </div>
          </div>

          <div class="popup__label">
            <label for="start">Nombre de séances</label>
            <input
              class="popup__input"
              type="number"
              name="number"
              id="number"
              v-model="nbrSeance"
            />
          </div>
        </div>
        <div class="popup__box--flex">
          <div class="popup__label">
            <label for="start">Notes</label>
            <textarea
              class="popup__input"
              placeholder="Your notes"
              v-model="notes"
            ></textarea>
          </div>
          <div class="popup__images">
            <div class="popup__images-title">
              <label for="start">Photos</label>
              <button @click="chooseHandler">upload image</button>
              <input
                type="file"
                ref="imageInput"
                style="display: none"
                @change="previewImage"
                accept="image/*"
              />
            </div>
            <div class="popup__images-list" v-if="images.length > 0">
              <img
                v-for="img in images"
                :key="img"
                :src="img"
                :alt="img"
                @click="zoomImageHandler(img)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup__delete">
      <button @click="deleteEventHandler">Delete</button>
    </div>
    <div class="popup__image" v-if="zoomImage">
      <img :src="zoomImage" :alt="zoomImage" />
      <button class="image__close" @click="zoomImageHandler(null)">
        Close
      </button>
      <button class="image__delete" @click="deleteImageHandler">Delete</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  name: "PopupEvent",
  data() {
    return {
      type: null,
      dateDebut: {
        jour: null,
        mois: null,
        annee: null
      },
      dateFin: {
        jour: null,
        mois: null,
        annee: null
      },
      nbrSeance: null,
      notes: null,
      images: [],
      imageUpload: null,
      zoomImage: null
    };
  },
  computed: {
    data: {
      get() {
        this.setPropreties(this.$store.state.popupEventData);
        return this.$store.state.popupEventData;
      }
    },
    conseils() {
      const conseils = this.$store.state.conseils;

      const conseilsOfEvent = conseils.filter(
        el =>
          el.name.toLowerCase() == this.data.type.split(" ")[0].toLowerCase()
      );

      // const randomConseil =
      //   conseilsOfEvent[0].data[
      //     Math.floor(Math.random() * conseilsOfEvent[0].data.length)
      //   ];

      return conseilsOfEvent[0].data;
    },
    currentYear() {
      return parseInt(new Date().getFullYear());
    }
  },
  methods: {
    getImgUrl(pic) {
      // Get the images
      switch (pic) {
        case "Découverte de la maladie":
          return require("../assets/progression/decouverte.svg");
        case "Chimiothérapie taxotere":
          return require("../assets/progression/chimiotherapie_taxotere.svg");
        case "Chimiothérapie epirubicine":
          return require("../assets/progression/chimioterapie_epirubicine.svg");
        case "Radiothérapie":
          return require("../assets/progression/radiotherapie.svg");
        case "Chimiothérapie xeloda":
          return require("../assets/progression/chimioterapie_xeloda.svg");
        case "Chirurgie":
          return require("../assets/progression/reconstruction.svg");
        case "1 an de traitement":
          return require("../assets/progression/1an_de_traitement.svg");
      }
    },
    range: function(min, max) {
      let array = [],
        j = 0;
      for (let i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
    setPropreties(val) {
      const dateDebutVal = val.dateDebut.split("-");
      const dateFinVal = val.dateFin.split("-");

      this.type = val.type;
      this.dateDebut.jour = parseInt(dateDebutVal[2]);
      this.dateDebut.mois = parseInt(dateDebutVal[1]);
      this.dateDebut.annee = parseInt(dateDebutVal[0]);
      this.dateFin.jour = parseInt(dateFinVal[2]);
      this.dateFin.mois = parseInt(dateFinVal[1]);
      this.dateFin.annee = parseInt(dateFinVal[0]);
      this.nbrSeance = parseInt(val.nbrSeance);
      this.notes = val.notes;
      this.images = val.images !== undefined ? val.images : [];
    },
    chooseHandler() {
      this.$refs.imageInput.click();
    },
    previewImage(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("bad files");
      }
      this.imageUpload = files[0];
      this.onUpload();
    },
    onUpload() {
      const metadata = {
        contentType: `${this.imageUpload.type}`
      };

      const filename = this.imageUpload.name;

      const storageRef = firebase
        .storage()
        .ref(`${this.$store.state.userId}/${filename}`)
        .put(this.imageUpload, metadata);

      storageRef.on(
        `state_changed`,
        snapshot => {
          console.log(
            "Upload ",
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        error => {
          console.log(error.message);
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            console.log(url);
            this.images.push(url);
            this.setNewEvent();
          });
        }
      );
    },
    setNewEvent() {
      console.log("setNewEvent");
      const obj = {
        type: "events",
        data: {
          dateDebut:
            this.dateDebut.annee +
            "-" +
            this.dateDebut.mois +
            "-" +
            this.dateDebut.jour,
          dateFin:
            this.dateFin.annee +
            "-" +
            this.dateFin.mois +
            "-" +
            this.dateFin.jour,
          type: this.type,
          nbrSeance: this.nbrSeance,
          notes: this.notes,
          images: this.images
        }
      };

      this.$store.commit("updateUser", obj);
      this.$store.dispatch("updateUserOther");
    },
    closeEvent() {
      this.setNewEvent();
      this.$store.dispatch("popupEventOpen", null);
    },
    zoomImageHandler(url) {
      this.zoomImage = url;
    },
    deleteImageHandler() {
      const arrayImagesDeleted = this.images.filter(
        el => el !== this.zoomImage
      );

      this.images = arrayImagesDeleted;

      this.setNewEvent();
      this.zoomImageHandler(null);
    },
    deleteEventHandler() {
      this.$store.commit("deleteEventUser", "events");
      this.$store.dispatch("updateUserOther");
      this.$store.dispatch("popupEventOpen", null);
    }
  }
};
</script>

<style lang="scss">
.popup {
  width: 100vw;
  min-height: 100vh;
  background-color: #f4f5fa !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  overflow: scroll;
  padding: 40px;
  &__box {
    display: flex;
    flex-direction: column;

    align-items: stretch;
    &--flex {
      min-width: 50%;
    }
  }

  &__close {
    button {
      background-image: url("../assets/arrow.svg");
      background-repeat: no-repeat;
      overflow: hidden;
      display: block;
      text-indent: 300%;
      border: none;
      background-color: inherit;
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: fixed;
      left: 20px;
      top: 20px;
    }
  }
  &__img {
    width: 50%;
    margin: 30px auto;
  }

  &__list {
    font-family: "Lora", serif;
    color: #252e48;
    font-weight: 600;
  }
  &__tips {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.13);
    h4 {
      color: #95cdcb;
      font-size: 20px;
      font-family: "Lora", serif;
      padding-bottom: 10px;
    }
    ul {
      margin: 10px;
      text-align: left;
    }
    li {
      padding-bottom: 10px;
    }
  }
  &__label {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    font-size: 16px;
    color: #3b5150;
    text-align: left;
  }
  &__input {
    padding: 20px;
    border: none;
    background-color: #fff;
    border-radius: 5px;
    column-gap: 20px;
    box-shadow: 0px 10px 10px rgba(13, 78, 129, 0.051);
  }
  &__date {
    display: flex;
    flex-direction: row;
    width: 100%;
    place-content: space-between;
  }
  label {
    margin-bottom: 10px;
  }
  &__images-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;

    button {
      background-image: url(../assets/upload.svg);
      background-repeat: no-repeat;
      overflow: hidden;
      display: block;
      text-indent: 100%;
      border: none;
      background-color: inherit;
      width: 20px;
      height: 20px;
      background-size: contain;
    }
  }
  &__images-list {
    display: flex;
    column-gap: 40px;
    flex-wrap: wrap;

    grid-gap: 40px;
    img {
      width: 40%;
    }
  }
  &__delete {
    button {
      background-color: #fa5b5b;
      color: #fff;
      padding: 15px 30px 15px 30px;
      border: none;
      font-weight: 600;
      font-size: 15px;
      display: block;
      margin: 20px auto;
      border-radius: 18px;
      text-transform: uppercase;
    }
  }
}
.image {
  &__delete {
    background-image: url(../assets/icon/delete.svg);
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    text-indent: 100%;
    border: none;
    background-color: inherit;
    width: 40px;
    height: 40px;
    background-size: contain;
    position: fixed;
    bottom: 20px;
    cursor: pointer;
  }

  &__close {
    background-image: url(../assets/icon/cross.svg);
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    text-indent: 100%;
    border: none;
    background-color: inherit;
    width: 20px;
    height: 20px;
    background-size: contain;
    position: fixed;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

@media (min-width: 850px) {
  .popup {
    &__desktop {
      width: 80%;
      margin: 0 auto;
    }
    &__img {
      width: 23%;
      margin: 0 auto;
    }
    &__tips {
      width: 29em;
    }
    &__delete {
      position: fixed;
      bottom: 0px;
      right: 20px;
      button {
        font-size: 10px !important;
        cursor: pointer;
        &:hover {
          background-color: #f81111;
          transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
        }
      }
    }
    &__close {
      button {
        left: 60px;
        top: 40px;
        cursor: pointer;
      }
    }
    &__input {
      margin: 0px 20px 0px 0px;
    }
    &__box {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      align-content: space-around;
      width: 100%;
      flex-direction: row;
      column-gap: 150px;
      img {
        width: 100%;
      }
      &--desktop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        column-gap: 150px;
      }
    }
    &__list {
      font-size: 26px;
      text-align: left;
      margin-top: 20px;
    }
    &__images-list {
      display: flex;
      column-gap: 20px;

      img {
        width: 30%;
      }
    }
  }
}

@media (max-width: 325px) {
  .popup {
    &__input {
      padding: 8px !important;
    }
  }
  .modal {
    &__date {
      column-gap: 2px;
    }
  }
}

@media (max-width: 375px) {
  .popup {
    &__input {
      padding: 14px;
    }
  }
  .modal {
    &__date {
      column-gap: 14px;
    }
  }
}
</style>

<template>
  <svg viewBox="0 0 17.333 19">
    <g>
      <path
        d="M0,5A5.055,5.055,0,0,1,5.1,0a5.055,5.055,0,0,1,5.1,5,5.055,5.055,0,0,1-5.1,5A5.055,5.055,0,0,1,0,5Z"
        transform="translate(4.078)"
      />
      <path
        d="M0,8V3.809A8.387,8.387,0,0,1,7.055,0h3.223a8.388,8.388,0,0,1,7.056,3.809V8Z"
        transform="translate(0 11)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ProfilIco"
};
</script>

<style scoped>
path {
  fill: #6d7278;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Splashscreen from "../views/Splashscreen.vue";

// import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "splashscreen",
    component: Splashscreen
  },
  {
    path: "/test",
    name: "test",
    component: () => import(/* webpackChunkName: "test" */ "../views/Test.vue")
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue")
  },
  {
    path: "/agenda",
    name: "agenda",
    component: () =>
      import(/* webpackChunkName: "agenda" */ "../views/Agenda.vue")
  },
  {
    path: "/profil",
    name: "profil",
    component: () =>
      import(/* webpackChunkName: "profil" */ "../views/Profil.vue")
  },
  {
    path: "/timeline",
    name: "timeline",
    component: () =>
      import(/* webpackChunkName: "timeline" */ "../views/Timeline.vue")
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (!isLogged()) {
    if (
      to.name !== "login" &&
      to.name !== "splashscreen" &&
      to.name !== "register"
    ) {
      next({ name: "splashscreen" });
    } else {
      next();
    }
  } else {
    if (
      to.name === "splashscreen" ||
      to.name === "login" ||
      to.name === "register"
    ) {
      next({ name: "home" });
    } else {
      next();
    }
  }
});

const isLogged = () => {
  const token = localStorage.getItem("token");
  const expirationDate = new Date(localStorage.getItem("expirationDate"));
  const now = new Date();

  return token && now <= expirationDate;
};

export default router;

<template>
  <svg viewBox="0 0 23.426 21">
    <g>
      <path
        d="M20.5,4.758a3.645,3.645,0,0,0-3.273-1.973H15.045a1.844,1.844,0,0,1-3.532,0h-.472a1.844,1.844,0,0,1-3.532,0H4.74a2.294,2.294,0,0,1-.654,1.18,2.419,2.419,0,0,1-1.693.68A2.383,2.383,0,0,1,0,2.323a2.4,2.4,0,0,1,4.743-.448H7.506a1.843,1.843,0,0,1,3.539,0h.465a1.843,1.843,0,0,1,3.539,0h2.173a4.581,4.581,0,0,1,4.115,2.479A.457.457,0,0,1,20.885,5,.423.423,0,0,1,20.5,4.758Z"
      />
      <path
        d="M16.738,11.11h-3.87a1.794,1.794,0,0,1-3.467,0H8.942a1.795,1.795,0,0,1-3.468,0H4.5a4.495,4.495,0,1,1,0-8.989h.974a1.794,1.794,0,0,1,3.468,0H9.4a1.794,1.794,0,0,1,3.467,0H15A3.58,3.58,0,0,0,18.157.235a.461.461,0,0,1,.811.435A4.5,4.5,0,0,1,15,3.04H12.868a1.794,1.794,0,0,1-3.467,0H8.942a1.794,1.794,0,0,1-3.468,0H4.5a3.575,3.575,0,1,0,0,7.15h.974a1.794,1.794,0,0,1,3.468,0H9.4a1.794,1.794,0,0,1,3.467,0h3.873a2.367,2.367,0,0,1,2.3-1.877,2.344,2.344,0,0,1,2.347,2.344,2.348,2.348,0,0,1-4.65.453Z"
        transform="translate(2.037 8)"
      />
      <path
        d="M.609.01A.514.514,0,0,0,.01.4.505.505,0,0,0,.41.99.514.514,0,0,0,1.009.6.506.506,0,0,0,.609.01Z"
        transform="translate(20.37 6)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "TimelineIco"
};
</script>

<style scoped>
path {
  fill: #6d7278;
}
</style>

<template>
  <div class="splash">
    <div class="splash__img" v-if="windowWidth < 850">
      <transition name="fade" mode="out-in">
        <img
          src="../assets/splash/logo.png"
          alt="logo"
          v-if="slide === 0"
          key="image0"
        />
        <img
          src="../assets/splash/progression.png"
          alt="progression"
          v-if="slide === 1"
          key="image1"
        />
        <img
          src="../assets/splash/agenda.png"
          alt="agenda"
          v-if="slide === 2"
          key="image2"
        />
        <img
          src="../assets/splash/partage.png"
          alt="partage"
          v-if="slide === 3"
          key="image3"
        />
      </transition>
    </div>

    <div class="splash__dots" v-if="windowWidth < 850">
      <ul>
        <li @click="changeSlideHandler(0)">
          <span :class="[slide === 0 ? 'active' : '']">btn</span>
        </li>
        <li @click="changeSlideHandler(1)">
          <span :class="[slide === 1 ? 'active' : '']">btn</span>
        </li>
        <li @click="changeSlideHandler(2)">
          <span :class="[slide === 2 ? 'active' : '']">btn</span>
        </li>
        <li @click="changeSlideHandler(3)">
          <span :class="[slide === 3 ? 'active' : '']">btn</span>
        </li>
      </ul>
    </div>

    <div class="splash__content" v-if="windowWidth < 850">
      <transition name="fade" mode="out-in">
        <div v-if="slide === 0" key="text0">
          <h2>Breast Diary</h2>
          <p>
            Une web app qui a pour but, d’accompagner quelqu’un atteint du
            cancer du seins dans son organisation et sa progression. Tout en le
            partager avec ses proches.
          </p>
        </div>
        <div v-if="slide === 1" key="text1">
          <h2>Progression</h2>
          <p>
            Un visuel retraçant la progression des phases de la maladie et le
            traitement du patient et où ils en est.
          </p>
        </div>
        <div v-if="slide === 2" key="text2">
          <h2>Evolution</h2>
          <p>
            Retranscrire les différents rendez-vous et pouvoir y ajouter des
            notes écrites, vocales et photos
          </p>
        </div>
        <div v-if="slide === 3" key="text3">
          <h2>Partage</h2>
          <p>
            Pouvoir partager avec son entourage la progression de la maladie
            tout en ayant le choix de designer un tuteur.
          </p>
        </div>
      </transition>
    </div>

    <div class="splash__controllers" v-if="windowWidth < 850">
      <router-link to="/login">Passer les étapes</router-link>
      <a href="#" @click.prevent="changeSlideHandler('inc')">Suivant</a>
    </div>

    <div class="splash__desktop" v-if="windowWidth >= 850">
      <div class="splash__desktop--intro">
        <img src="../assets/splash/logo.png" alt="image logo " />
        <p>
          Une web app qui a pour but, d’accompagner quelqu’un atteint du cancer
          du seins dans son organisation et sa progression. Tout en le partager
          avec ses proches.
        </p>
        <button v-scroll-to="'#info'">Découvrir</button>
      </div>
      <div id="info" class="splash__desktop--info" ref="div1">
        <ul>
          <li>
            <img src="../assets/splash/progression.png" alt="progression" />
            <h2>Progression</h2>
            <p>
              Un visuel retraçant la progression des phases de la maladie et le
              traitement du patient et où ils en est.
            </p>
          </li>
          <li>
            <img src="../assets/splash/agenda.png" alt="agenda" />
            <h2>Evolution</h2>
            <p>
              Retranscrire les différents rendez-vous et pouvoir y ajouter des
              notes écrites, vocales et photos
            </p>
          </li>
          <li>
            <img src="../assets/splash/partage.png" alt="partage" />
            <h2>Partage</h2>
            <p>
              Pouvoir partager avec son entourage la progression de la maladie
              tout en ayant le choix de designer un tuteur.
            </p>
          </li>
        </ul>
        <router-link to="/login">
          <button>Web app</button>
        </router-link>
        <div class="footer">
          <small>Réalisée par </small>
          <small>Gabriella Arvanitis</small>
          <small>© DWM | TFE - BETA | HEAJ</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      windowWidth: window.innerWidth
    };
  },
  methods: {
    changeSlideHandler(nbrSlide) {
      if (nbrSlide === "inc") {
        if (this.slide === 3) {
          this.$router.push({ name: "login" });
        }
        this.slide++;
        return;
      }

      this.slide = nbrSlide;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style scoped lang="scss">
.splash {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 30px;
  margin: auto;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../assets/background.png");
    background-size: 100% auto;
    z-index: -2;
  }

  &::after {
    content: "";
    width: 100%;
    height: 70%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url("../assets/motif.png");
    background-size: 100% 100%;
    z-index: -1;
  }

  &__img {
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 80%;
      width: auto;
    }
  }

  &__dots {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 70px;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0;
      list-style: none;
      width: 50%;

      li {
        padding: 0;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-indent: 100%;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #e3e3e3;
          display: block;

          &.active {
            background-color: #95cdcb;
          }
        }
      }
    }
  }

  &__content {
    height: 20%;
    display: flex;
    flex-direction: column;

    h2 {
      margin-bottom: 25px;
    }
    p {
      margin: 20px;
    }
  }

  &__controllers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    a {
      text-decoration: none;
      color: #b4b4b4;

      &:last-child {
        color: #fff;
        text-transform: uppercase;
        background-color: #95cdcb;
        padding: 15px 25px;
        border-radius: 25px;
      }
    }
  }
  &__desktop {
    background-image: url("../assets/background_desktop.svg");
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    &::after {
      content: " ";
      background-image: url("../assets/splash/after_landing.svg");
      background-size: contain;
      z-index: 0;
      height: 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      width: 100%;
      bottom: -10px;
      background-position: bottom;
    }
    &--intro {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        width: 30em;
        padding: 20px;
        font-size: 18px;
        color: #a5a5a5;
      }
      img {
        width: 200px;
      }
    }
    button {
      color: #fff;
      padding: 15px 30px;
      background-color: #95cdcb;
      border: none;
      font-weight: 600;
      font-size: 15px;
      display: block;
      margin: 20px auto;
      border-radius: 18px;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      z-index: 1000;
    }
    &--info {
      ul {
        display: flex;
        width: 100%;
        column-gap: 40px;
        border-radius: 5px;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        li {
          width: 300px;
          background-color: #fff;
          padding: 20px;
          height: auto;
          height: 400px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-content: center;
          align-items: center;
          margin-bottom: 40px;
          img {
            height: 200px;
          }
          p {
            color: #a5a5a5;
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.footer {
  display: flex;
  padding: 20px 40px;
  font-size: 13px;
  text-align: left;
  line-height: 1.8;
  height: 90px;
  width: 100%;
  background-color: #fad1d1;
  display: flex;
  flex-direction: column;
}
</style>

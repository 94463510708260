<template>
  <div id="app">
    <Navbar v-show="isNavDisplayed" />
    <transition name="view">
      <router-view />
    </transition>
    <Modal v-show="isModalOpen" />
    <PopupEvent v-if="isPopupEventOpen" />
    <PopupAgenda v-if="isPopupAgendaOpen" />
  </div>
</template>

<script>
import Modal from "./components/Modal";
import Navbar from "./components/Navbar";
import PopupEvent from "./components/PopupEvent.vue";
import PopupAgenda from "./components/PopupAgenda.vue";

export default {
  components: {
    Modal,
    PopupEvent,
    PopupAgenda,
    Navbar
  },
  created() {
    // Autologin dispatch
    this.$store.dispatch("tryAutoLogin");
  },
  computed: {
    // Get to know if modal is open in store
    isModalOpen() {
      return this.$store.state.isModalOpen;
    },
    isPopupEventOpen() {
      return this.$store.state.isPopupEventOpen;
    },
    isPopupAgendaOpen() {
      return this.$store.state.isPopupAgendaOpen;
    },
    isNavDisplayed() {
      console.log(this.$route);
      return (
        this.$route.name !== "splashscreen" &&
        this.$route.name !== "login" &&
        this.$route.name !== "register"
      );
    }
  },
  methods: {
    // Log out method
    onLogout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://unpkg.com/v-animate-css/dist/v-animate-css.js");
body {
  background-color: #f4f5fa;
}
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  padding-bottom: 70px;

  height: 100%;
  &__constru {
    display: flex;
    margin: auto;
    width: 100%;
    height: 40vh;
    justify-content: space-between;
    p {
      text-transform: uppercase;
      font-weight: 800;
      top: 50%;
      position: relative;
      margin: auto;
      font-size: 30px;
    }
  }
}

h2 {
  font-size: 25px;
}
h3 {
  margin-bottom: 20px;
  font-size: 20px;
}

.home {
  display: block;
}

section {
  display: block;
}

.desktop {
  display: none;
}

.view-enter-active,
.view-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.view-enter-active {
  transition-delay: 0.1s;
}

.view-enter,
.view-leave-to {
  opacity: 0;
}

.view-enter-to,
.view-leave {
  opacity: 1;
}
</style>

<template>
  <div class="nav">
    <div class="nav__logo" v-if="windowWidth > 850">
      <img src="../assets/splash/logo.png" alt="#" />
    </div>
    <router-link to="/home" class="nav__el">
      <HomeIco />
      <span>Home</span>
    </router-link>
    <router-link to="/timeline" class="nav__el">
      <TimelineIco />
      <span>Progression</span>
    </router-link>
    <div class="nav__add" @click="onClickAdd" v-if="windowWidth <= 850">+</div>
    <router-link to="/agenda" class="nav__el">
      <AgendaIco />
      <span>Agenda</span>
    </router-link>
    <router-link to="/profil" class="nav__el">
      <ProfilIco />
      <span>Profil</span>
    </router-link>
    <div class="nav__add" @click="onClickAdd" v-if="windowWidth > 850">+</div>
  </div>
</template>

<script>
import AgendaIco from "./icons/AgendaIco.vue";
import HomeIco from "./icons/HomeIco.vue";
import ProfilIco from "./icons/ProfilIco.vue";
import TimelineIco from "./icons/TimelineIco.vue";

export default {
  name: "Navbar",
  components: {
    HomeIco,
    TimelineIco,
    AgendaIco,
    ProfilIco
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  methods: {
    onClickAdd() {
      this.$store.commit("setModal");
      console.log("[Add] Clicked");
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style lang="scss">
.nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 65px;
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  color: #6d7278;
  z-index: 10;

  &__logo {
    img {
      width: 100%;
      height: auto;
      padding: 5px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
    width: 62px;

    &.router-link-exact-active {
      color: #95cdcb;
      opacity: 1;

      svg,
      path {
        fill: #95cdcb;
      }
    }
  }

  &__add {
    background-color: #fff;
    color: #fbd1d1;
    border-radius: 50%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 60px;
    height: 60px;
    font-size: 35px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 30px;
  }

  &__el {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 5px;
      width: 20px;
      height: 20px;
    }
  }
}
@media (min-width: 850px) {
  .nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: 0px 26px 26px 0px;
    background-color: #fff;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12px;
    color: #6d7278;
    z-index: 99;
    width: 60px;

    span {
      display: none;
    }
    &__add {
      width: 40px;
      height: 40px;
      cursor: pointer;
      &:hover {
        transform: rotate(-90deg);
        transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
        background-color: #fbd1d1;
        color: #fff;
      }
    }
    svg:hover {
      width: 25px;
      height: 25px;
      transform: rotate(-90deg);
      transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
    }
  }

  .router-link-exact-active {
    span {
      display: block;
      transform: rotate(-90deg);
      background-color: #f4f5fa;
      padding: 10px 20px 27px 20px;
      border-radius: 20px 20px 0px 0px;
      margin-left: 10px;
    }
    svg {
      display: none;
    }
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase";
import vueScrollto from "vue-scrollto";

Vue.use(vueScrollto);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    const firebaseConfig = {
      apiKey: "AIzaSyBV93SDIQGCse2SDd5YjcISWAV9UfPrSzw",
      authDomain: "tfe-breastdiary.firebaseapp.com",
      databaseURL:
        "https://tfe-breastdiary-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "tfe-breastdiary",
      storageBucket: "tfe-breastdiary.appspot.com",
      messagingSenderId: "160032494836",
      appId: "1:160032494836:web:93985111ccdbb92ddabaa1",
      measurementId: "G-GS22ED4TJY"
    };

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }
}).$mount("#app");

<template>
  <svg viewBox="0 0 19.432 19">
    <g>
      <path
        d="M1.707,19A1.691,1.691,0,0,1,0,17.33V3.9A1.691,1.691,0,0,1,1.707,2.227H3.486A3.784,3.784,0,0,1,3.848.959,1.518,1.518,0,0,1,5.162,0,1.516,1.516,0,0,1,6.475.959a3.741,3.741,0,0,1,.362,1.267h1.2A3.762,3.762,0,0,1,8.4.959,1.518,1.518,0,0,1,9.716,0,1.517,1.517,0,0,1,11.03.959a3.762,3.762,0,0,1,.362,1.267h1.2A3.762,3.762,0,0,1,12.956.959,1.518,1.518,0,0,1,14.27,0a1.518,1.518,0,0,1,1.314.959,3.762,3.762,0,0,1,.362,1.267h1.778A1.691,1.691,0,0,1,19.432,3.9V17.33A1.691,1.691,0,0,1,17.724,19Z"
      />
      <path
        d="M.55,8A.56.56,0,0,1,0,7.431V.569A.56.56,0,0,1,.55,0H13.767a.56.56,0,0,1,.551.569V7.431A.56.56,0,0,1,13.767,8Z"
        transform="translate(2.045 9)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "AgendaIco"
};
</script>

<style scoped>
path {
  fill: #6d7278;
}
</style>

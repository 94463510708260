<template>
  <svg viewBox="0 0 22 22">
    <g>
      <g>
        <rect width="4" height="7" transform="translate(9 12)" />
        <path
          d="M18.872,9.526,11.847,2.276a.9.9,0,0,0-1.294,0L3.528,9.535A1.849,1.849,0,0,0,3,10.851V18.56A1.832,1.832,0,0,0,4.722,20.4H7.556V12.12a.916.916,0,0,1,.911-.92h5.467a.916.916,0,0,1,.911.92V20.4h2.834A1.832,1.832,0,0,0,19.4,18.56V10.851a1.914,1.914,0,0,0-.528-1.325Z"
          transform="translate(0 -0.4)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "HomeIco"
};
</script>

<style scoped>
path {
  fill: #6d7278;
}
</style>

import Vue from "vue";
import Vuex from "vuex";

import axios_auth from "../axios/axios-auth";
import axios_base from "../axios/axios-base";
import axios_refresh from "../axios/axios-refresh";

import router from "../router/index";

import google_api from "../keys/keys";

import conseils from "../assets/conseils.json";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idToken: null,
    userId: null,
    user: null,
    refreshToken: null,
    isModalOpen: false,
    isPopupEventOpen: false,
    popupEventData: null,
    isPopupAgendaOpen: false,
    popupAgendaData: null,
    conseils: conseils,
    errorLogin: null
  },
  mutations: {
    authUser(state, userData) {
      state.idToken = userData.token;
      state.userId = userData.userId;
      state.refreshToken = userData.refreshToken;
    },
    storeUser(state, user) {
      state.user = user;
    },
    clearAuthData(state) {
      state.idToken = null;
      state.userId = null;
    },
    setModal(state) {
      state.isModalOpen = !state.isModalOpen;
    },
    emptyUser(state) {
      state.user = null;
    },
    saveNewDataUser(state, data) {
      if (state.user[data.type] !== undefined) {
        const sameObject = state.user[data.type].filter(
          el => el.type === data.data.type
        );
        if (sameObject.length > 0 && data.type === "events") {
          const newArray = state.user[data.type].map(el => {
            if (el.type === data.data.type) {
              return data.data;
            } else {
              return el;
            }
          });
          state.user[data.type] = newArray;
        } else {
          state.user[data.type].push(data.data);
        }
      } else {
        state.user[data.type] = [data.data];
      }
    },
    updateUser(state, data) {
      let indexOld = null;
      if (data.type === "events") {
        indexOld = state.user[data.type].indexOf(state.popupEventData);
      } else {
        indexOld = state.user[data.type].indexOf(state.popupAgendaData);
      }
      console.log(indexOld);

      state.user[data.type][indexOld] = data.data;
    },
    deleteEventUser(state, name) {
      let indexOld = null;
      if (name === "events") {
        indexOld = state.user[name].indexOf(state.popupEventData);
      } else {
        indexOld = state.user[name].indexOf(state.popupAgendaData);
      }
      console.log(indexOld);

      state.user[name].splice(indexOld, 1);
    },
    setPopupEvent(state, event) {
      state.isPopupEventOpen = !state.isPopupEventOpen;
      state.popupEventData = event;
    },
    setPopupAgenda(state, event) {
      state.isPopupAgendaOpen = !state.isPopupAgendaOpen;
      state.popupAgendaData = event;
    },
    setErrorLogin(state, err) {
      state.errorLogin = err;
    }
  },
  actions: {
    setLogoutTimer({ dispatch }, expirationTime) {
      setTimeout(() => {
        dispatch("refreshLogin");
      }, expirationTime * 1000);
    },
    refreshLogin({ commit, dispatch }) {
      axios_refresh
        .post(`/token?key=${google_api}`, {
          grant_type: "refresh_token",
          refresh_token: this.state.refreshToken
        })
        .then(res => {
          console.log(res);

          commit("authUser", {
            token: res.data.id_token,
            userId: res.data.user_id,
            refreshToken: res.data.refresh_token
          });

          const now = new Date();
          const expirationDate = new Date(
            now.getTime() + res.data.expires_in * 1000
          );

          localStorage.setItem("token", res.data.id_token);
          localStorage.setItem("userId", res.data.user_id);
          localStorage.setItem("refreshToken", res.data.refresh_token);
          localStorage.setItem("expirationDate", expirationDate);

          dispatch("setLogoutTimer", res.data.expires_in);
          dispatch("fetchUser");
        })
        .catch(err => {
          console.log(err);
          commit("clearAuthData");
          dispatch("logout");
        });
    },
    signup({ commit, dispatch }, authData) {
      axios_auth
        .post(`/accounts:signUp?key=${google_api}`, {
          email: authData.email,
          password: authData.password,
          returnSecureToken: true
        })
        .then(res => {
          console.log("Auth: ", res);
          // Save id's user in the store
          commit("authUser", {
            token: res.data.idToken,
            userId: res.data.localId,
            refreshToken: res.data.refreshToken
          });
          // Set expiration date in the store and localstorage
          const now = new Date();
          const expirationDate = new Date(
            now.getTime() + res.data.expiresIn * 1000
          );
          localStorage.setItem("token", res.data.idToken);
          localStorage.setItem("userId", res.data.localId);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          localStorage.setItem("expirationDate", expirationDate);
          // dispatch new actions
          dispatch("createUser", authData);
          dispatch("setLogoutTimer", res.data.expiresIn);
        })
        .catch(error => console.log(error));
    },
    login({ commit, dispatch }, authData) {
      commit("setErrorLogin", null);
      axios_auth
        .post(`/accounts:signInWithPassword?key=${google_api}`, {
          email: authData.email,
          password: authData.password,
          returnSecureToken: true
        })
        .then(res => {
          console.log(res);
          // Set expiration date
          const now = new Date();
          const expirationDate = new Date(
            now.getTime() + res.data.expiresIn * 1000
          );
          // Set the localStorage
          localStorage.setItem("token", res.data.idToken);
          localStorage.setItem("userId", res.data.localId);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          localStorage.setItem("expirationDate", expirationDate);
          // Save it in the store
          commit("authUser", {
            token: res.data.idToken,
            userId: res.data.localId,
            refreshToken: res.data.refreshToken
          });
          // Set the timer
          dispatch("setLogoutTimer", res.data.expiresIn);
          dispatch("fetchUser");
          router.replace("/home").catch(() => {});
        })
        .catch(error => {
          commit("setErrorLogin", error.response.data);
        });
    },
    tryAutoLogin({ commit, dispatch }) {
      console.log("[AutoLogin] Start process");
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      console.log("[AutoLogin] Token finded");
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      const now = new Date();
      console.log("[AutoLogin] Test timer");
      if (now >= expirationDate) {
        return;
      }
      const expirationTime = Math.floor(
        (expirationDate.getTime() - now.getTime()) / 1000
      );
      console.log("[AutoLogin] Timer good");
      const userId = localStorage.getItem("userId");
      const refreshToken = localStorage.getItem("refreshToken");
      commit("authUser", {
        token: token,
        userId: userId,
        refreshToken: refreshToken
      });
      dispatch("setLogoutTimer", expirationTime);

      console.log("[AutoLogin] Get data user");
      dispatch("fetchUser");
    },
    logout({ commit }) {
      commit("clearAuthData");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      router.replace("/");
    },
    createUser({ state, dispatch }, userData) {
      if (!state.idToken) {
        return;
      }

      const events = [];

      const agenda = [];

      const data = {
        pseudo: userData.pseudo,
        email: userData.email,
        tel: "",
        typeCancer: ""
      };

      const user = {
        data,
        events,
        agenda
      };

      axios_base
        .put(
          "/datastore/users/" +
            state.userId +
            ".json" +
            "?auth=" +
            state.idToken,
          user
        )
        .then(res => {
          console.log(res);
          dispatch("fetchUser");
          router.replace("/home").catch(() => {});
        })
        .catch(error => console.log(error));
    },
    updateUserData({ state, dispatch }, userData) {
      if (!state.idToken) {
        return;
      }

      axios_base
        .put(
          "/datastore/users/" +
            state.userId +
            "/data.json" +
            "?auth=" +
            state.idToken,
          userData
        )
        .then(res => {
          console.log(res);
          dispatch("fetchUser");
        })
        .catch(error => console.log(error));
    },
    fetchUser({ commit, state }) {
      if (!state.idToken) {
        return;
      }

      axios_base
        .get(`/datastore/users/${state.userId}.json?auth=${state.idToken}`)
        .then(res => {
          console.log(res);
          const data = res.data;
          console.log(data);
          commit("storeUser", data);
        })
        .catch(error => console.log(error));
    },
    updateUserOther({ dispatch, commit, state }) {
      console.log("[updateUserOther]");
      axios_base
        .put(
          "/datastore/users/" +
            state.userId +
            ".json" +
            "?auth=" +
            state.idToken,
          state.user
        )
        .then(res => {
          console.log(res);
          commit("emptyUser");
          dispatch("fetchUser");
        })
        .catch(error => console.log(error));
    },
    popupEventOpen({ commit }, event) {
      commit("setPopupEvent", event);
    },
    popupAgendaOpen({ commit }, event) {
      commit("setPopupAgenda", event);
    }
  },
  getters: {
    user(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.idToken;
    }
  }
});
